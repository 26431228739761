<template>
  <v-card flat>
    <v-col cols="12">
      <v-alert
        type="info"
        outlined
        dense
        class="mb-0"
      >
        {{ $t('scheduleWidgetInformation') }}
      </v-alert>
    </v-col>
    <v-form v-model="valid">
      <template v-for="field in fieldsWithValues">
        <v-col
          v-if="field.show"
          :key="field.key"
          :cols="field.cols ? field.cols : '12'"
        >
          <UiField
            :value="field"
            @change="setFieldValue"
          />
        </v-col>
      </template>
    </v-form>
  </v-card>
</template>

<script>
import UiField from "@/components/uiField"

export default {
  name: "ScheduleWidgetForm",
  components: {
    UiField
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      valid: false,
      scheduleWidget: null,
      fieldsWithValues: []
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'organization',
          label: this.$t('organization'),
          editable: false,
          show: true,
          type: 'select',
          options: this.organizations,
          value: this.organization.uuid,
          disabled: true,
          required: true,
          rules: [this.rules.required]
        },
        {
          key: 'label',
          label: this.$t('label'),
          editable: true,
          show: true,
          type: 'text',
          required: true,
          rules: [this.rules.required],
          value: null
        },
        {
          key: 'active',
          label: this.$t('active'),
          editable: false,
          show: false,
          type: 'switch',
          value: null
        },
        {
          key: 'channel',
          label: this.$t('channel'),
          editable: true,
          show: true,
          type: 'autocomplete',
          multiple: false,
          options: this.channels,
          required: true,
          rules: [this.rules.required],
          value: null
        },
        {
          key: 'location',
          label: this.$t('location'),
          editable: true,
          show: true,
          type: 'autocomplete',
          multiple: false,
          options: this.locations,
          value: null
        },
        {
          key: 'items',
          label: this.$t('items'),
          editable: true,
          show: true,
          type: 'autocomplete',
          multiple: true,
          options: this.items,
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('limitByItems')
        },
        {
          key: 'categories',
          label: this.$t('categories'),
          editable: true,
          show: true,
          type: 'autocomplete',
          multiple: true,
          options: this.categories,
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('limitByCategories')
        },
        {
          key: 'defaultLanguage',
          label: this.$t('defaultLanguage'),
          editable: true,
          show: true,
          type: 'select',
          multiple: false,
          value: null,
          options: this.languages
        },
        {
          key: 'mode',
          label: this.$t('mode'),
          editable: true,
          show: true,
          type: 'select',
          multiple: false,
          options: this.modes
        },
        {
          key: 'showAsCategories',
          label: this.$t('showAsCategories'),
          type: 'switch',
          editable: true,
          show: false,
          value: null
        },
        {
          key: 'darkTheme',
          label: this.$t('darkTheme'),
          editable: true,
          show: true,
          type: 'switch',
          value: null
        },
        {
          key: 'showImages',
          label: this.$t('showImages'),
          editable: true,
          show: true,
          type: 'switch',
          value: null
        },
        {
          key: 'booking',
          label: this.$t('booking'),
          editable: false,
          show: true,
          type: 'text',
          value: null
        },
        {
          key: 'hideExtraInfo',
          label: this.$t('hideExtraInfo'),
          editable: true,
          show: true,
          type: 'switch',
          value: null
        },
        {
          key: 'showUpsellItemsAsSwitch',
          label: this.$t('showUpsellItemsAsSwitch'),
          editable: true,
          show: true,
          type: 'switch',
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('upsellItemsInToggleMode')
        },
        {
          key: 'mainButtonsRounded',
          label: this.$t('mainButtonsRounded'),
          type: 'switch',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'mainButtonsCapitalized',
          label: this.$t('mainButtonsCapitalized'),
          type: 'switch',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'mainButtonsColor',
          label: this.$t('mainButtonsColor'),
          type: 'color',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'backgroundColor',
          label: this.$t('backgroundColor'),
          type: 'color',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'textColor',
          label: this.$t('textColor'),
          type: 'color',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'primaryColor',
          label: this.$t('primaryColor'),
          type: 'color',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'minimalFooter',
          label: this.$t('minimalFooter'),
          type: 'switch',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'hideItemPrice',
          label: this.$t('hideItemPrice'),
          type: 'switch',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'useSlotPrices',
          label: this.$t('useSlotPrices'),
          type: 'switch',
          editable: true,
          show: true,
          value: null
        },
        {
          key: 'activitiesCategory',
          label: this.$t('activitiesCategory'),
          type: 'select',
          editable: true,
          show: true,
          multiple: false,
          options: this.categories,
          value: null
        },
        {
          key: 'showAsCategories',
          label: this.$t('showAsCategories'),
          type: 'switch',
          editable: true,
          show: true,
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('showAsCategoriesInfo')
        },
        {
          key: 'intervalBetweenSlotsInMinutes',
          label: this.$t('intervalBetweenSlotsInMinutes'),
          type: 'number',
          editable: true,
          show: true,
          value: null,
          hideDetails: false,
          persistentHint: true,
          hint: this.$t('intervalBetweenSlotsInMinutesHint'),
          clearable: true,
          min: 0,
        }, 
        {
          key: 'metaPixelCode',
          label: this.$t('metaPixelCode'),
          type: 'text',
          editable: true,
          show: true,
          value: null,
          persistentHint: true,
          hint: this.$t('trackingIdForMetaPixel'),
          clearable: true,
          min: 0,
        },
        {
            key: 'allowSelectionOfMultipleItems',
            label: this.$t('allowSelectionOfMultipleItems'),
            type: 'switch',
            editable: true,
            show: true,
            value: false,
            hideDetails: false,
            persistentHint: true,
            hint: this.$t('allowSelectionOfMultipleItemsHint'),
            group: 'experimental-feature'
        }
      ]
    },
    channels() {
      return this.$store.getters.channelsFormatForUiFields()
    },
    locations() {
      return this.$store.getters.locationsFormatForUiFields()
    },
    items() {
      const items = this.$store.state.items
      return this.$store.getters.itemsFormatForUiFields(items.filter(item => item && item.type === 'event' && (item.sellOnline === true || item.sellOnline === null || typeof item.sellOnline === 'undefined') && item.status === true || item.status === '1'))
    },
    categories() {
      let categories = this.$store.state.categories
      categories = categories.filter(category => category && category.administrative !== true && category.active !== false)
      if(Array.isArray(categories) && categories.length > 0) {
        return this.$store.getters.categoriesFormatForUiFields(categories)
      }
      return []
    },
    languages() {
      return [
        {
          label: 'Íslenska',
          key: 'is'
        },
        {
          label: 'English',
          key: 'en'
        }
      ]
    },
    modes() {
      return [
        {
          label: 'Mode 0 - Original',
          key: '0'
        },
        {
          label: 'Mode 1 - Show Available Item Time Slots by Date and Quantity',
          key: '1'
        },
        {
          label: 'Mode 2 - Show available time slots by selected items',
          key: '2'
        }
      ]
    },
    organization() {
      return this.$store.state.organization
    },
    organizations() {
      const currentOrganization = this.organization
      if(currentOrganization) {
        return [{
          key: currentOrganization.uuid,
          label: currentOrganization.label
        }]
      }
      return []
    },
    rules() {
      return this.$store.getters.rules
    }
  },
  methods: {
    setFieldValue({ field, value }) {
      this.scheduleWidget[field.key] = value
      this.$emit('input', this.scheduleWidget)
    },
    setFieldValues() {
      const newFields = []
      for(const index in this.fields) {
        const field = this.fields[index]
        if(field && field.key) {
          if(typeof this.scheduleWidget[field.key] !== 'undefined') {
            field.value = this.scheduleWidget[field.key]
            this.fields[index].value = this.scheduleWidget[field.key]
          }
        }
        newFields.push(field)
      }
      this.fieldsWithValues = newFields
    }
  },
  watch: {
    valid(value) {
      this.$emit('valid', value)
    }
  },
  mounted() {
    this.scheduleWidget = this.value
    if(typeof this.scheduleWidget !== 'undefined' && this.scheduleWidget !== null) {
      this.setFieldValues()
    }
  },
  destroyed() {
    this.scheduleWidget = null
  }
}
</script>

<style scoped>

</style>